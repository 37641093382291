import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilCk5(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck5/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_detil = [
    {
      title: "MERK",
      width: 230,
      key: "nama_merk",
      dataIndex: "nama_merk",
      fixed: "left",
    },
    {
      title: "URAIAN BARANG",
      width: 320,
      key: "uraian_barang",
      dataIndex: "uraian_barang",
    },
    {
      title: "NO KOLLI",
      key: "nomor_kolli",
      dataIndex: "nomor_kolli",
      width: 100,
    },
    {
      title: "JENIS KOLLI",
      key: "jenis_kolli",
      dataIndex: "jenis_kolli",
      width: 100,
    },
    {
      title: "JUMLAH CUKAI",
      key: "jumlah_cukai",
      dataIndex: "jumlah_cukai",
      width: 180,
      render: (jumlah_cukai) => (
        <span>
          {jumlah_cukai
            ? jumlah_cukai.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "JUMLAH DEVISA",
      key: "jumlah_devisa",
      dataIndex: "jumlah_devisa",
      width: 180,
      render: (jumlah_devisa) => (
        <span>
          {jumlah_devisa
            ? jumlah_devisa.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },

    {
      title: "KETERANGAN",
      key: "keterangan",
      dataIndex: "keterangan",
      width: 100,
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Perusahaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan ? `${dataHeader[0].nama_perusahaan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          {/* <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
                  <div className="col-lg-12 col-md-18 col-sm-24">
                    <Form.Item label={"Alamat Perusahaan"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                      <TextArea disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
                    </Form.Item>
                  </div>
                </div> */}
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal Aju"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_aju && dataHeader[0].tanggal_aju
                      ? `${dataHeader[0].nomor_aju} / ${moment(dataHeader[0].tanggal_aju).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal CK-5"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_ck5 && dataHeader[0].tanggal_ck5
                      ? `${dataHeader[0].nomor_ck5} / ${moment(dataHeader[0].tanggal_ck5).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Jenis BKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jenis_bkc ? `${dataHeader[0].jenis_bkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Jenis Pemberitahuan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jenis_pemberitahuan ? `${dataHeader[0].jenis_pemberitahuan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Cara Pelunasan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].cara_pelunasan ? `${dataHeader[0].cara_pelunasan}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Status Cukai"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].status_cukai ? `${dataHeader[0].status_cukai}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Impor / Dalam Negeri"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].impor_dn ? `${dataHeader[0].impor_dn}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Negara Tujuan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].negara_tujuan ? `${dataHeader[0].negara_tujuan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal Invoice"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_invoice && dataHeader[0].tanggal_invoice
                      ? `${dataHeader[0].nomor_invoice} / ${moment(dataHeader[0].tanggal_invoice).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal Fasiitas"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_fasilitas && dataHeader[0].tanggal_fasilitas
                      ? `${dataHeader[0].nomor_fasilitas} / ${moment(dataHeader[0].tanggal_fasilitas).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Cara Angkut"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].cara_angkut ? `${dataHeader[0].cara_angkut}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Jangka Waktu"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jangka_waktu ? `${dataHeader[0].jangka_waktu}` : "-"} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <div
          className="form-group row"
          style={{
            marginTop: 15,
            marginBottom: 0,
          }}>
          <div className="col-lg-6 col-md-9 col-sm-12">
            <Card title="DATA ASAL / PEMASOK">
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"No. Identitas"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].nomor_identitas_pemasok ? `${dataHeader[0].nomor_identitas_pemasok}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Nama"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].nama_pemasok ? `${dataHeader[0].nama_pemasok}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Alamat"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <TextArea disabled value={dataHeader[0].alamat_pemasok ? `${dataHeader[0].alamat_pemasok}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Kantor"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].kantor_pemasok ? `${dataHeader[0].kantor_pemasok}` : "-"} />
                  </Form.Item>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-6 col-md-9 col-sm-12">
            <Card title="DATA TUJUAN / PENGGUNA">
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"No. Identitas"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].nomor_identitas_pengguna ? `${dataHeader[0].nomor_identitas_pengguna}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Nama"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].nama_pengguna ? `${dataHeader[0].nama_pengguna}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Alamat"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <TextArea disabled value={dataHeader[0].alamat_pengguna ? `${dataHeader[0].alamat_pengguna}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Kantor"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].kantor_pengguna ? `${dataHeader[0].kantor_pengguna}` : "-"} />
                  </Form.Item>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Card title="PENIMBUNAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"No. Identitas"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_identitas_penimbunan ? `${dataHeader[0].nomor_identitas_penimbunan}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_penimbunan ? `${dataHeader[0].nama_penimbunan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"Alamat"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <TextArea disabled value={dataHeader[0].alamat_penimbunan ? `${dataHeader[0].alamat_penimbunan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"Kantor"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].kantor_penimbunan ? `${dataHeader[0].kantor_penimbunan}` : "-"} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <div
          className="form-group row"
          style={{
            marginTop: 15,
            marginBottom: 0,
          }}>
          <div className="col-lg-6 col-md-9 col-sm-12">
            <Card title="DATA MUAT">
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Pelabuhan"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].pelabuhan_muat ? `${dataHeader[0].pelabuhan_muat}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Kantor"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].kantor_muat ? `${dataHeader[0].kantor_muat}` : "-"} />
                  </Form.Item>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-6 col-md-9 col-sm-12">
            <Card title="DATA SINGGAH">
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Pelabuhan"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].pelabuhan_singgah ? `${dataHeader[0].pelabuhan_singgah}` : "-"} />
                  </Form.Item>
                </div>
              </div>
              <div
                className="form-group row"
                style={{
                  marginTop: 15,
                  marginBottom: 0,
                }}>
                <div className="col-lg-12 col-md-18 col-sm-24">
                  <Form.Item
                    label={"Kantor"}
                    colon={false}
                    wrapperCol={{
                      sm: 24,
                    }}
                    style={{
                      width: "100%",
                      marginRight: 0,
                    }}>
                    <Input disabled value={dataHeader[0].kantor_singgah ? `${dataHeader[0].kantor_singgah}` : "-"} />
                  </Form.Item>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_detil}
              scroll={{
                x: 1180,
              }}
            />
          </div>
        </Card>
        <Card title="CATATAN">
          <Card title="1. Hasil Pemeriksaan dan Pengeluaran Asal">
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Hasil Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].hasil_pemeriksaan_1 ? `${dataHeader[0].hasil_pemeriksaan_1}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"No Polisi/VOY/Flight"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].voy_flight_1 ? `${dataHeader[0].voy_flight_1}` : "-"} />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pemeriksaan_1 ? `${moment(dataHeader[0].tanggal_pemeriksaan_1).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pengeluaran_1 ? `${moment(dataHeader[0].tanggal_pengeluaran_1).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pemeriksa"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pemeriksaan_1 ? `${dataHeader[0].nip_nama_pemeriksaan_1}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pengeluaran_1 ? `${dataHeader[0].nip_nama_pengeluaran_1}` : "-"} />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Card title="2. Hasil Pemeriksaan Pemasukan BKC di Tempat Tujuan/Penimbunan Terakhir">
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Hasil Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].hasil_pemeriksaan_2 ? `${dataHeader[0].hasil_pemeriksaan_2}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"No Polisi/VOY/Flight"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].voy_flight_1 ? `${dataHeader[0].voy_flight_2}` : "-"} />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pemeriksaan_2 ? `${moment(dataHeader[0].tanggal_pemeriksaan_2).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pengeluaran_2 ? `${moment(dataHeader[0].tanggal_pengeluaran_2).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pemeriksa"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pemeriksaan_2 ? `${dataHeader[0].nip_nama_pemeriksaan_2}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pengeluaran_2 ? `${dataHeader[0].nip_nama_pengeluaran_2}` : "-"} />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Card title="3. Hasil Pemeriksaan Sebelum Pemuatan">
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Hasil Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].hasil_pemeriksaan_3 ? `${dataHeader[0].hasil_pemeriksaan_3}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"No Polisi/VOY/Flight"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].voy_flight_3 ? `${dataHeader[0].voy_flight_3}` : "-"} />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pemeriksaan_3 ? `${moment(dataHeader[0].tanggal_pemeriksaan_3).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pengeluaran_3 ? `${moment(dataHeader[0].tanggal_pengeluaran_3).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pemeriksa"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pemeriksaan_3 ? `${dataHeader[0].nip_nama_pemeriksaan_3}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pengeluaran_3 ? `${dataHeader[0].nip_nama_pengeluaran_3}` : "-"} />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Card title="4. Hasil Pemeriksaan di Pelabuhan Singgah Terakhir">
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Hasil Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].hasil_pemeriksaan_4 ? `${dataHeader[0].hasil_pemeriksaan_4}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"No Polisi/VOY/Flight"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].voy_flight_4 ? `${dataHeader[0].voy_flight_4}` : "-"} />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pemeriksaan"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pemeriksaan_4 ? `${moment(dataHeader[0].tanggal_pemeriksaan_4).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"Tanggal Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input
                    disabled
                    value={dataHeader[0].tanggal_pengeluaran_4 ? `${moment(dataHeader[0].tanggal_pengeluaran_4).format("DD-MM-YYYY")}` : "-"}
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pemeriksa"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pemeriksaan_4 ? `${dataHeader[0].nip_nama_pemeriksaan_4}` : "-"} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <Form.Item
                  label={"NIP / Nama Pengeluaran"}
                  colon={false}
                  wrapperCol={{
                    sm: 24,
                  }}
                  style={{
                    width: "100%",
                    marginRight: 0,
                  }}>
                  <Input disabled value={dataHeader[0].nip_nama_pengeluaran_4 ? `${dataHeader[0].nip_nama_pengeluaran_4}` : "-"} />
                </Form.Item>
              </div>
            </div>
          </Card>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilCk5;
