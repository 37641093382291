import React, { Fragment, useState, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, Input, Space } from "antd";
import DetilCk from "./detilCk";
import DetilMmea from "./detilMmea";
import DetilNonPita from "./detilNonPita";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { Icon } from "leaflet";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Ck1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetilCk: false,
      showDetilMmea: false,
      showDetilNonPita: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck1/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          // Authorization: token,
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetilCk: false,
      showDetilMmea: false,
      showDetilNonPita: false,
    });
  };

  detilCK = (idCK, bkc, golonganPabrik) => {
    if (bkc === "HT") {
      this.setState({
        idCK: idCK,
        showDetilCk: true,
      });
      console.log("BKC: ", bkc);
      console.log("golonganPabrik: ", golonganPabrik);
    } else if (bkc === "MMEA" && golonganPabrik === "A") {
      this.setState({
        idCK: idCK,
        showDetilNonPita: true,
      });
      console.log("BKC: ", bkc);
      console.log("golonganPabrik: ", golonganPabrik);
    } else {
      this.setState({
        idCK: idCK,
        showDetilMmea: true,
      });
      console.log("BKC: ", bkc);
      console.log("golonganPabrik: ", golonganPabrik);
    }
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const tabelck = [
      {
        title: "NPPBKC",
        width: 100,
        key: "nppbkc",
        dataIndex: "nppbkc",
        fixed: "left",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        dataIndex: "namaPerusahaan",
        width: 300,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("namaPerusahaan"),
        fixed: "left",
      },
      {
        title: "NO CK-1/A",
        key: "nomorCk1",
        dataIndex: "nomorCk1",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nomorCk1"),
      },
      {
        title: "TANGGAL CK-1/A",
        key: "tanggalCk1",
        dataIndex: "tanggalCk1",
        width: 160,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("tanggalCk1"),
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "JENIS BAYAR",
        key: "caraBayar",
        dataIndex: "caraBayar",
        sortDirections: ["descend", "ascend"],
        width: 160,
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "JENIS BKC",
        key: "jenisBkc",
        dataIndex: "jenisBkc",
        render: (text) => {
          return text ? text : "-";
        },
      },
      {
        title: "GOLONGAN",
        key: "golonganPabrik",
        dataIndex: "golonganPabrik",
        render: (text) => {
          return text ? text : "-";
        },
      },
      {
        title: "TOTAL CUKAI",
        key: "totalCukai",
        dataIndex: "totalCukai",
        render: (totalCukai) => (
          <span>
            {totalCukai
              ? totalCukai.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                })
              : "Rp. -"}
          </span>
        ),
      },
      {
        title: "CUKAI PENGURANG",
        key: "cukaiPengurang",
        dataIndex: "cukaiPengurang",
        render: (cukaiPengurang) => (
          <span>
            {cukaiPengurang
              ? cukaiPengurang.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                })
              : "Rp. -"}
          </span>
        ),
      },

      {
        title: "CUKAI DIBAYAR",
        key: "cukaiDibayar",
        dataIndex: "cukaiDibayar",
        render: (cukaiDibayar) => (
          <span>
            {cukaiDibayar
              ? cukaiDibayar.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                })
              : "Rp. -"}
          </span>
        ),
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
        fixed: "right",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("status"),
        width: 100,
      },
      {
        title: "#AKSI",
        key: "idCk1",
        dataIndex: "idCk1",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.idCk1
              .toString()
              .split(",")
              .map((idCk1) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilCK(row.idCk1, row.jenisBkc, row.golonganPabrik)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                );
                return <span> {button} </span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA CK-1">
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={tabelck}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 2500,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA CK-1 HT"} placement="right" width={drawerWidth} open={this.state.showDetilCk} onClose={this.handleOnClose}>
            <DetilCk idCK={this.state.idCK} />
          </Drawer>
          <Drawer title={"DETIL DATA CK-1 MMEA"} placement="right" width={drawerWidth} open={this.state.showDetilMmea} onClose={this.handleOnClose}>
            <DetilMmea idCK={this.state.idCK} />
          </Drawer>
          <Drawer
            title={"DETIL DATA CK-1 MMEA TANPA PITA"}
            placement="right"
            width={1080}
            open={this.state.showDetilNonPita}
            onClose={this.handleOnClose}>
            <DetilNonPita idCK={this.state.idCK} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Ck1;
