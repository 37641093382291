//router
import IndexRouters from "./router/index";

//scss
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function App() {
  let timeoutId;
  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {
    //check token empty
    if (!token) {
      //redirect login page
      history.push("/");
    }

    //call function "fetchData"
  }, [token]);

  function resetTimeout() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(logout, 2 * 60 * 60 * 1000); // Set timeout for 2 hours
  }

  useEffect(() => {
    resetTimeout();
    return () => clearTimeout(timeoutId);
  }, []);

  function logout() {
    // Lakukan perintah logout di sini
    // Contoh: panggil fungsi yang akan menghapus session atau mengirim permintaan ke backend untuk logout
    localStorage.removeItem("token");

    //redirect halaman login
    history.push("/");
  }
  return (
    <div className="App">
      <IndexRouters />
    </div>
  );
}

export default App;
