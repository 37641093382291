import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const { Option } = Select;

function FormDPabrikMMEA(props) {
  return (
    <Fragment>
      <div>
        <Card title="Data Kapasitas Pengusaha Minuman Mengandung Etil Alkohol">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Produksi</label>
              <div className="col-3">
                <Select style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <h6 class="kt-portlet__head-title">Rekam Rata-Rata Produksi Perbulan</h6>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Golongan MMEA</label>
              <div className="col-3">
                <Select style={{ width: "100%" }} />
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Jumlah</label>
              <div className="col-3">
                <Select style={{ width: "100%" }} /> Liter
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <div className="col-3">
                <button className="btn btn-sm btn-success">
                  <i className="fa fa-upload" />
                  Simpan Dokumen
                </button>
              </div>
              <div className="col-3">
                <Button type="primary">Reset</Button>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
          <label className="col-1 col-form-label"></label>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Jenis Proses Produksi</label>
            <div className="col-3">
              <Select style={{ width: "100%" }} />
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Gudang Barang Jadi</label>
            <div className="col-3">
              <Select style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Jumlah Pemasok Langsung EA </label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
            </div>
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <button className="btn btn-sm btn-success">
              <i className="fa fa-upload" />
              Simpan Form A
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormDPabrikMMEA;
