import React from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// user
import Mesin from "../views/mesin/index";
import Mutasi from "../views/mesin/mutasi";

const MesinRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {/* <Route path="/dashboard" exact component={Index} /> */}
          {/* user */}
          <Route path="/mesin" exact component={Mesin} />
          <Route path="/mesin/mutasi" exact component={Mutasi} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default MesinRouter;
