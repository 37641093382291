import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, Checkbox } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const { Option } = Select;

function FormDPabrikHT(props) {
  return (
    <Fragment>
      <div>
        <Card title="Data Penanggung Jawab dan Pengurus">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <h5 class="kt-portlet__head-title">Jenis dan Jumlah Mesin</h5>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-6">
                <Checkbox>
                  Mesin Pencampuran, Jumlah <input style={{ width: "10%" }} type="number" /> Unit
                </Checkbox>
              </div>
              <div className="col-6">
                <Checkbox>
                  Mesin Pelinting, Jumlah <input style={{ width: "10%" }} type="number" /> Unit
                </Checkbox>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-12">
                <Checkbox>
                  Mesin Pengepakan, Jumlah <input style={{ width: "8%" }} type="number" /> Unit, Kapasitas Produksi/Menit Jumlah{" "}
                  <input style={{ width: "10%" }} type="number" /> Bungkus
                </Checkbox>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <h5 class="kt-portlet__head-title">Data Mesin Pencampuran</h5>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Merek </label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Kapasitas Terpasang</label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="number" />
              </div>
            </div>

            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Tipe</label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="text" />
              </div>
              <label className="col-1 col-form-label"></label>
              <label className="col-2 col-form-label">Status Penguasaan</label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="text" />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor</label>
              <div className="col-3">
                <Input style={{ color: "black", marginRight: "5px" }} type="text" />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-3">
                <button className="btn btn-sm btn-success">
                  <i className="fa fa-upload" />
                  Simpan Dokumen
                </button>
              </div>
              <div className="col-3">
                <Button type="primary">Reset</Button>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>
        <Card>
          <div className="form-group form-group-xs row">
            <h5 class="kt-portlet__head-title">Data Mesin Pencampuran</h5>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Merek </label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="Text" />
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Kapasitas Terpasang</label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="number" />
            </div>
          </div>

          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Tipe</label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="text" />
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Status Penguasaan</label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="text" />
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Nomor</label>
            <div className="col-3">
              <Input style={{ color: "black", marginRight: "5px" }} type="text" />
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <button className="btn btn-sm btn-success">
                <i className="fa fa-upload" />
                Simpan Dokumen
              </button>
            </div>
            <div className="col-3">
              <Button type="primary">Reset</Button>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>
        <Card>
          <div className="form-group form-group-xs row">
            <h5 class="kt-portlet__head-title">Jumlah Alat Linting/Giling (manual) dan Tenaga Linting/Giling</h5>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-6">
              <Checkbox>
                Alat Linting/Giling, Jumlah <input style={{ width: "10%" }} type="number" /> Unit
              </Checkbox>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-12">
              <Checkbox>
                Tenaga Linting, Jumlah rata-rata/hari <input style={{ width: "10%" }} type="number" /> Orang, Rata-rata Produksi/hari/orang{" "}
                <input style={{ width: "10%" }} type="number" /> Batang
              </Checkbox>
            </div>
          </div>
          <label className="col-3 col-form-label"></label>
          <div className="form-group form-group-xs row">
            <label className="col-3 col-form-label">Jumlah HJE yang dimiliki</label>
            <div className="col-3">
              <Select style={{ width: "100%" }}>
                <Option value="1">1</Option>
              </Select>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-3 col-form-label">Status Tenaga Linting</label>
            <div className="col-3">
              <Select style={{ width: "100%" }}>
                <Option value="1">1</Option>
              </Select>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-3 col-form-label">Proses Produksi</label>
            <div className="col-3">
              <Select style={{ width: "100%" }}>
                <Option value="1">1</Option>
              </Select>
            </div>
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <button className="btn btn-sm btn-success">
              <i className="fa fa-upload" />
              Simpan Form A
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormDPabrikHT;
