import React, { useState, useEffect, Fragment, useRef } from "react";
import { Card, Form, Input, Image, QRCode, Table } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";

function DetilMesin(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const [dataTracking, setDataTracking] = useState([""]);
  const idMesin = props.idMesin;
  const [visible, setVisible] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const qrCodeRef = useRef(null);
  const token = localStorage.getItem("token");

  console.log("idMesin DetilMesin: ", props.idMesin);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/detil-mesin?id=" + idMesin, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: token,
          },
        })
        .then((res) => {
          const result = res.data.body.data.data[0];
          setDataHeader(result);
        })
        .catch((error) => {});
    };
    const getDataTracking = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/detil/tracking?id=" + idMesin, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: token,
          },
        })
        .then((res) => {
          const result = res.data.body.data.data[0].detil;
          setDataTracking(result);
        })
        .catch((error) => {});
    };

    getDataHeader();
    getDataTracking();
  }, [idMesin, token]);

  const printQRCode = () => {
    setIsPrinting(true);
    html2canvas(qrCodeRef.current).then((canvas) => {
      const qrcodeImage = canvas.toDataURL("image/png");

      const printWindow = window.open();
      printWindow.document.write("<html><body>");
      printWindow.document.write(`<img src="${qrcodeImage}" />`);
      printWindow.document.write("</body></html>");
      printWindow.print();

      setIsPrinting(false);
    });
  };
  console.log("TRACKING: ", dataTracking);

  const tracking = [
    {
      title: "ALAMAT",
      width: 180,
      key: "alamat",
      dataIndex: "alamat",
      fixed: "left",
    },
    {
      title: "LONGLAT",
      key: "longlat",
      dataIndex: "longlat",
      width: 120,
    },
    {
      title: "USER REKAM",
      key: "user_update",
      dataIndex: "user_update",
      width: 120,
    },
    {
      title: "TERAKHIR UPDATE",
      key: "updated_at",
      dataIndex: "updated_at",
      width: 120,
    },
    {
      title: "KETERANGAN",
      key: "keterangan",
      dataIndex: "keterangan",
      width: 100,
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      width: 100,
      fixed: "right",
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NPPBKC"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NAMA PERUSAHAAN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="DATA MESIN">
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"MERK"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.merk} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"JENIS MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.jenis_mesin} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"SERIAL NUMBER"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.serial_number} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"TIPE MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.tipe_mesin} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"JENIS PRODUKSI"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.jenis_produksi} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"TAHUN PEMBUATAN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.tahun_pembuatan} />
              </Form.Item>
            </div>
          </div>

          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"KAPASITAS"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.kapasitas} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"NEGARA PEMBUAT"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.negara_pembuat} />
              </Form.Item>
            </div>
          </div>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"KONDISI"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.kondisi} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"STATUS"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <Input disabled value={dataHeader.status_kepemilikkan} />
              </Form.Item>
            </div>
          </div>
          <Card title="RIWAYAT MESIN">
            <div
              className="form-group row"
              style={{
                marginTop: 15,
                marginBottom: 0,
              }}>
              <Table
                dataSource={dataTracking}
                columns={tracking}
                scroll={{
                  x: 1500,
                }}
              />
            </div>
          </Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"FOTO MESIN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {dataHeader &&
                    dataHeader.foto_mesin &&
                    Array.isArray(dataHeader.foto_mesin) &&
                    dataHeader.foto_mesin.map((foto) => (
                      <div style={{ margin: "4px" }}>
                        <Image
                          preview={{
                            visible: false,
                          }}
                          width={120}
                          height={120}
                          src={foto.url_foto}
                          onClick={() => setVisible(true)}
                        />
                      </div>
                    ))}
                </div>
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item label={"BARCODE"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                <div style={{ position: "relative" }}>
                  <button style={{ position: "absolute", top: 0, right: 0 }} onClick={() => printQRCode()} disabled={isPrinting}>
                    {isPrinting ? "Mencetak..." : "Cetak QR Code"}
                  </button>

                  <div ref={qrCodeRef}>
                    <QRCode value={dataHeader.no_identifikasi} size={300} />
                    <span style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center", display: "block", width: "300px" }}>
                      {dataHeader.no_identifikasi}
                    </span>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
        </Card>
        {/* <Card title="RINCIAN">
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table
              dataSource={dataHeader}
              columns={tbl_detil}
              scroll={{
                x: 2200,
              }}
            />
          </div>
        </Card> */}
      </div>
      <div
        style={{
          display: "none",
        }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => setVisible(vis),
          }}>
          {dataHeader &&
            dataHeader.foto_mesin &&
            Array.isArray(dataHeader.foto_mesin) &&
            dataHeader.foto_mesin.map((foto) => <Image src={foto.url_foto} />)}
        </Image.PreviewGroup>
      </div>
      ,
    </Fragment>
  );
}
export default DetilMesin;
