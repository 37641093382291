import React, { Fragment, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, Input, Space } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import DetilCK5HT from "./detilHtCk5";
import DetilCK7MMEA from "./detilMmea";
import DetilCK7HT from "./detilHT";
import DetilCK5MMEA from "./detilMmeaCk5";
const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Ck2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetilCK5HT: false,
      showDetilCK5MMEA: false,
      showDetilCK7HT: false,
      showDetilCK7MMEA: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck2/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetilCK5HT: false,
      showDetilCK5MMEA: false,
      showDetilCK7HT: false,
      showDetilCK7MMEA: false,
    });
  };

  detilCK2 = (idCK, asal, bkc) => {
    if (bkc === "HT" && asal === "CK5") {
      this.setState({
        idCK: idCK,
        showDetilCK5HT: true,
      });
      console.log("BKC: ", bkc);
      console.log("asal: ", asal);
    } else if (bkc === "MMEA" && asal === "CK5") {
      this.setState({
        idCK: idCK,
        showDetilCK5MMEA: true,
      });
      console.log("BKC: ", bkc);
      console.log("asal: ", asal);
    } else if (bkc === "HT" && asal === "PBCK7") {
      this.setState({
        idCK: idCK,
        showDetilCK7HT: true,
      });
      console.log("BKC: ", bkc);
      console.log("asal: ", asal);
    } else {
      this.setState({
        idCK: idCK,
        showDetilCK7MMEA: true,
      });
      console.log("BKC: ", bkc);
      console.log("asal: ", asal);
    }
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const CK = [
      {
        title: "NPPBKC",
        width: 100,
        key: "nppbkc",
        dataIndex: "nppbkc",
        fixed: "left",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        width: 250,
        dataIndex: "namaPerusahaan",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("namaPerusahaan"),
      },
      {
        title: "NO CK-2",
        key: "nomorCk2",
        dataIndex: "nomorCk2",
        width: 250,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nomorCk2"),
        render: (text) => {
          return text ? text : "-";
        },
      },
      {
        title: "TANGGAL CK-2",
        key: "tanggalCk2",
        dataIndex: "tanggalCk2",
        width: 160,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("tanggalCk2"),
        render: (text) => {
          return text ? moment(text).format("DD-MM-YYYY") : "-";
        },
      },
      {
        title: "ASAL",
        key: "asalDokumen",
        dataIndex: "asalDokumen",
        sortDirections: ["descend", "ascend"],
        width: 100,
        ...this.getColumnSearchProps("asalDokumen"),
      },

      {
        title: "JENIS BKC",
        key: "jenisBkc",
        dataIndex: "jenisBkc",
        width: 100,
      },
      {
        title: "TOTAL BIAYA PENGGANTI",
        key: "totalBiayaPengganti",
        dataIndex: "totalBiayaPengganti",
        width: 250,
        render: (totalBiayaPengganti) => (
          <span>
            {totalBiayaPengganti
              ? totalBiayaPengganti.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 2,
                })
              : "Rp. -"}
          </span>
        ),
      },
      {
        title: "SISA SALDO",
        key: "sisaSaldoCk2",
        dataIndex: "sisaSaldoCk2",
        width: 250,
        render: (sisaSaldoCk2) => (
          <span>
            {sisaSaldoCk2.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            })}
          </span>
        ),
      },
      {
        title: "TOTAL CUKAI",
        key: "totalCukai",
        dataIndex: "totalCukai",
        width: 250,
        render: (totalCukai) => (
          <span>
            {totalCukai.toLocaleString("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 2,
            })}
          </span>
        ),
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
      },
      {
        title: "#AKSI",
        key: "idCk2",
        dataIndex: "idCk2",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.idCk2
              .toString()
              .split(",")
              .map((idCk2) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilCK2(row.idCk2, row.asalDokumen, row.jenisBkc)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                );
                return <span> {button} </span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA CK-2">
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={CK}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 2000,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer
            title={"DETIL DATA CK2 HT ASAL CK5"}
            placement="right"
            width={drawerWidth}
            open={this.state.showDetilCK5HT}
            onClose={this.handleOnClose}>
            <DetilCK5HT idCK={this.state.idCK} />
          </Drawer>
          <Drawer
            title={"DETIL DATA CK2 MMEA ASAL CK5"}
            placement="right"
            width={drawerWidth}
            open={this.state.showDetilCK5MMEA}
            onClose={this.handleOnClose}>
            <DetilCK5MMEA idCK={this.state.idCK} />
          </Drawer>
          <Drawer
            title={"DETIL DATA CK2 MMEA ASAL PBCK7"}
            placement="right"
            width={drawerWidth}
            open={this.state.showDetilCK7MMEA}
            onClose={this.handleOnClose}>
            <DetilCK7MMEA idCK={this.state.idCK} />
          </Drawer>
          <Drawer
            title={"DETIL DATA CK2 HT ASAL PBCK7"}
            placement="right"
            width={drawerWidth}
            open={this.state.showDetilCK7HT}
            onClose={this.handleOnClose}>
            <DetilCK7HT idCK={this.state.idCK} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Ck2;
