import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, Checkbox, Radio } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const { Option } = Select;

function Penyalur(props) {
  return (
    <Fragment>
      <div>
        <Card title="Data Kapasitas Penyalur">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Jenis Barang</label>
              <div className="col-3">
                <Select style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Level dari Pabrik</label>
              <div className="col-3">
                <Select style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Jumlah Pemasok</label>
              <div className="col-3">
                <Input style={{ width: "100%" }} />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Golongan MMEA</label>
              <div className="col-1">
                <Checkbox value="1">A</Checkbox>
              </div>
              <div className="col-1">
                <Checkbox value="2">B</Checkbox>
              </div>
              <div className="col-1">
                <Checkbox value="3">C</Checkbox>
              </div>
            </div>
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <button className="btn btn-sm btn-success">
              <i className="fa fa-upload" />
              Simpan Form A
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Penyalur;
