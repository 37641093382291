import React from "react";

//router
import { Switch, Route } from "react-router";
//layoutpages
import Dashboard from "../layouts/dashboard";
import Cukai from "../layouts/cukai";
import SignIn from "../views/auth/login";
import Registrasi from "../layouts/registrasi";
import Rasio from "../layouts/rasio";
import Mesin from "../layouts/mesin";
import Tracking from "../layouts/tracking";

const IndexRouters = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={SignIn}></Route>
        <Route path="/dashboard" component={Dashboard}></Route>
        <Route path="/cukai" component={Cukai}></Route>
        <Route path="/registrasi" component={Registrasi}></Route>
        <Route path="/rasio" component={Rasio}></Route>
        <Route path="/mesin" component={Mesin}></Route>
        <Route path="/tracking" component={Tracking}></Route>
      </Switch>
    </>
  );
};

export default IndexRouters;
