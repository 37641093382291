import React from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// user
import Registrasi from "../views/registrasi/Index";

const RegistrasiRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {/* user */}
          <Route path="/registrasi" exact component={Registrasi} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RegistrasiRouter;
