import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, Checkbox } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const { Option } = Select;

function FormDPengusahaPenyimpanan(props) {
  return (
    <Fragment>
      <div>
        <Card title="Data Kapasitas Pengusaha Tempat Penyimpanan">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <div className="col-6">
                Rata-rata Pemasukan Per Bulan <input style={{ width: "50%" }} type="number" />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-12">
                Jumlah Tangki Penyimpanan <input style={{ width: "6%" }} type="number" /> Unit, Total Volume Maksimum{" "}
                <input style={{ width: "6%" }} type="number" /> Liter
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-6">
                Jumlah Pemasok Langsung <input style={{ width: "50%" }} type="Text" />
              </div>
            </div>
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <button className="btn btn-sm btn-success">
              <i className="fa fa-upload" />
              Simpan Form A
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormDPengusahaPenyimpanan;
