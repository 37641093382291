import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VerticalNav from "../SidebarStyle/vertical-nav";
import NavMenu from "../SidebarStyle/nav-menu";
import Scrollbar from "smooth-scrollbar";
import { bindActionCreators } from "redux";
// import SidebarDark from '../../components/settingoffcanvas'

// export const SidebarDark =() =>{

// }

// store
import {
  NavbarstyleAction,
  getDirMode,
  SchemeDirAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../../store/setting/setting";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
    },
    dispatch
  ),
});

const Sidebar = (props) => {
  useEffect(() => {
    // sidebarcolormode
    const sidebarcolorMode1 = sessionStorage.getItem("sidebarcolor-mode");
    if (sidebarcolorMode1 === null) {
      props.SidebarColorAction(props.sidebarcolorMode);
    } else {
      props.SidebarColorAction(sidebarcolorMode1);
    }

    // sidebarstylemode
    const sidebarstyleMode = sessionStorage.getItem("sidebarstyle-mode");
    if (sidebarstyleMode === null) {
      props.SidebarActiveStyleAction(props.sidebaractivestyleMode);
    } else {
      props.SidebarActiveStyleAction(sidebarstyleMode);
    }
    Scrollbar.init(document.querySelector("#my-scrollbar"));
  });
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  return (
    <>
      <aside className="sidebar sidebar-default navs-rounded-all {{ sidebarVariants }}">
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <Link to="/dashboard" className="navbar-brand">
            <svg width="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="evenodd" clipRule="evenodd">
                <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
                <path d="M184.252 113.504V79.251H8.504v34.253h175.748z" />
                <path
                  d="M132.465 111.873c1.121-1.428 0 0 0 0l2.039-2.957c1.428-2.242 4.078-2.242 5.607-2.344s11.162-.408 12.59-.51c0 0-.051-.713-.051-1.121l-20.236-13.762c-.918-.51-2.549-.714-2.549.102l-.203 2.293c0 1.02-1.58 1.121-2.396 1.121-.814 0-2.037 0-2.344-.917l-.459-3.313c-.102-.917 0-1.937 1.02-2.242 1.836-.408 6.27-.459 9.736 1.682l17.533 11.009c0-1.324.307-5.454 1.326-6.269l-5.812-6.932c-1.834-.51-1.834-3.364-.713-4.282 1.121-.917 2.855-.713 3.467-.204.783.653 1.121 1.733.713 2.957-.408 2.039.51 3.058 2.242 3.976l3.977 1.937c2.752 1.223 4.18 5.097 4.18 7.646v6.014c1.428.104 11.213.715 12.742.816s4.18.102 5.607 2.344l2.344 2.957V80.679h-60.758v31.194h10.398zM73.339 82.31v6.32h7.952v21.612h7.543V88.63h7.952v-6.32H73.339zM89.854 110.242l10.195-27.932h8.971l10.601 27.932h-7.545l-1.732-4.892H99.131l-1.735 4.892h-7.542z"
                  fill="#fff"
                />
                <path
                  fill="#fff"
                  d="M61.922 82.31v27.932h17.126v-6.32h-9.583V82.31h-7.543zM39.699 82.31v27.932h19.98v-6.32H47.242v-4.893h12.437v-6.321H47.242V88.63h12.437v-6.32h-19.98zM25.987 110.219c6.887 0 12.47-6.299 12.47-13.942s-5.583-13.942-12.47-13.942H11.358v27.908l14.629-.024z"
                />
                <path d="M104.533 90.567l3.873 9.888h-7.441l3.568-9.888zM25.987 88.635c2.428 0 4.397 3.421 4.397 7.641 0 4.22-1.969 7.64-4.397 7.64h-7.085V88.63l7.085.005z" />
              </g>
            </svg>
            <h4 className="logo-title">DELTA LINKS</h4>
          </Link>
          <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
            <i className="icon">
              <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.25 12.2744L19.25 12.2744" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path
                  d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>
              </svg>
            </i>
          </div>
        </div>
        <div className="pt-0 sidebar-body data-scrollbar" data-scroll="1" id="my-scrollbar">
          {/* sidebar-list class to be added after replace css */}
          <div className="sidebar-list navbar-collapse" id="sidebar">
            {/* //SUPER ADMIN// */}
            {/* <VerticalNav />  */}
            <NavMenu />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
