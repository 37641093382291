import React from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// user
import Nppbkc from "../views/cukai/nppbkc";
import P3c from "../views/cukai/pppc";
import Dppc from "../views/cukai/dppc";
import Ck1 from "../views/cukai/ck1";
import Ck2 from "../views/cukai/ck2";
import Ck3 from "../views/cukai/ck3";
import Ck4 from "../views/cukai/ck4";
import Ck5 from "../views/cukai/ck5";
import Ck6 from "../views/cukai/ck6";

const CukaiRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          {/* <Route path="/dashboard" exact component={Index} /> */}
          {/* user */}
          <Route path="/cukai/nppbkc/" exact component={Nppbkc} />
          <Route path="/cukai/pppc/" exact component={P3c} />
          <Route path="/cukai/dppc/" exact component={Dppc} />
          <Route path="/cukai/ck-1/" exact component={Ck1} />
          <Route path="/cukai/ck-2/" exact component={Ck2} />
          <Route path="/cukai/ck-3/" exact component={Ck3} />
          <Route path="/cukai/ck-4/" exact component={Ck4} />
          <Route path="/cukai/ck-5/" exact component={Ck5} />
          <Route path="/cukai/ck-6/" exact component={Ck6} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default CukaiRouter;
