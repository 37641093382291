import React, { Fragment, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Form, Button, Drawer, Input, Space } from "antd";
import DetilData from "./FormData";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class IndexRegis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetil: false,
    };
    this.handleForm = this.handleForm.bind(this);
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/registrasi/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleForm() {
    this.setState({
      visibleForm: true,
    });
  }

  handleOnClose = () => {
    this.setState({
      showDetil: false,
    });
  };

  detilData = (idRegis) => {
    this.setState({
      idRegis: idRegis,
      showDetil: true,
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const dataTables = [
      {
        title: "NPPBKC",
        width: 100,
        key: "nppbkc",
        dataIndex: "nppbkc",
        fixed: "left",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "NPWP",
        width: 130,
        key: "npwp",
        dataIndex: "npwp",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("npwp"),
      },
      {
        title: "PERUSAHAAN",
        key: "nama_perusahaan",
        dataIndex: "nama_perusahaan",
        width: 300,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nama_perusahaan"),
      },
      {
        title: "SKEP NPPBKC",
        key: "skep_nppbkc",
        dataIndex: "skep_nppbkc",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("skep_nppbkc"),
      },
      {
        title: "TANGGAL NPPBKC",
        key: "tanggal_nppbkc",
        dataIndex: "tanggal_nppbkc",
        width: 160,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("tanggal_nppbkc"),
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "JENIS USAHA",
        key: "jenis_usaha",
        dataIndex: "jenis_usaha",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("jenis_usaha"),
      },

      {
        title: "JENIS BKC",
        key: "jenisBkc",
        dataIndex: "jenisBkc",
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
        fixed: "right",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("status"),
        width: 130,
      },
      {
        title: "#AKSI",
        key: "nppbkc",
        dataIndex: "nppbkc",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.nppbkc
              .toString()
              .split(",")
              .map((nppbkc) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilData(row.nppbkc)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                );
                return <span>{button}</span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="REGISTRASI NPPBKC">
                <div className="table-responsive">
                  <Form>
                    <Form.Item
                      wrapperCol={{
                        xs: {
                          span: 24,
                          offset: 0,
                        },
                        sm: {
                          span: 30,
                          offset: 20,
                        },
                      }}>
                      <Button className="btn btn-sm btn-primary" onClick={this.handleForm}>
                        <i className="fa fa-plus" /> & nbsp; Tambah Data
                      </Button>
                    </Form.Item>
                    <Table
                      columns={dataTables}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 1000,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA REGISTRASI"} placement="right" width={drawerWidth} open={this.state.showDetil} onClose={this.handleOnClose}>
            <DetilData idCK={this.state.idRegis} />
          </Drawer>
          <Modal visible={this.state.visibleForm} title="Form" width={"90%"} onCancel={this.oncloseForm}>
            <DetilData />
            <div
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}></div>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default IndexRegis;
