import React, { useState, useRef, Fragment, useEffect } from "react";
import { Card, Form, Input, Button, Select, Upload, message } from "antd";
import axios from "axios";
import swal from "sweetalert";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { data } from "jquery";

function RekamMutasi(props) {
  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const { Option } = Select;
  const inputRef = useRef(null);
  const [noIdentifikasi, setNoIdentifikasi] = useState(null);
  const [tipeMesin, setTipeMesin] = useState([""]);
  const [jenisMesin, setJenisMesin] = useState([""]);
  const [merkMesin, setMerkMesin] = useState([""]);
  const [dataNppbkc, setDataNppbkc] = useState([""]);
  const [dataNppbkcBaru, setDataNppbkcBaru] = useState([""]);
  const [nppbkc, setNppbkc] = useState("");
  const [nppbkcBaru, setNppbkcBaru] = useState("");
  const [nama_perusahaan, setNama_Perusahaan] = useState("");
  const [alamat, setAlamat] = useState("");
  const [nama_perusahaanBaru, setNama_PerusahaanBaru] = useState("");
  const [alamatBaru, setAlamatBaru] = useState("");
  const [location, setLocation] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataHeader, setDataHeader] = useState([""]);
  const [dataHeaderMesin, setDataHeaderMesin] = useState([""]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const latlng = lat + "," + lng;
          setLocation(latlng);

          // Mengambil data alamat menggunakan Geocoding API
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [location]);

  const getDataNppbkc = () => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/nppbkc/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setDataNppbkc(result);
      })
      .catch((error) => {});
  };

  const getDataNppbkcBaru = () => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/nppbkc/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setDataNppbkcBaru(result);
      })
      .catch((error) => {});
  };

  const getDataHeader = (nppbkc) => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/browse/mesin/pengusaha?nppbkc=" + nppbkc, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        console.log("getDataHeader: ", result);
        setDataHeader(result);
      })
      .catch((error) => {});
  };
  const getDataHeaderMesin = (noIdentifikasi, nppbkc) => {
    axios
      .get(
        "https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/detil-mesin/nomor-identifikasi?noIdentifikasi=" + noIdentifikasi + "&nppbkc=" + nppbkc,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        const result = res.data.body.data.data;
        console.log("getDataHeaderMesin: ", result[0].serial_number);
        setDataHeaderMesin(result);
      })
      .catch((error) => {});
  };

  const getDataMerk = () => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/referensi/merk-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setMerkMesin(result);
      })
      .catch((error) => {});
  };
  // Get Data Tipe Mesin
  const getDataTipeMesin = () => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/referensi/tipe-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setTipeMesin(result);
      })
      .catch((error) => {});
  };

  // Get Data Jenis Produksi
  const getDataJenisProduksi = () => {
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/referensi/jenisproduksi-mesin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const result = res.data.body.data.data;
        setJenisMesin(result);
      })
      .catch((error) => {});
  };

  const handleGetDataMesin = (value) => {
    const selectedNoIdentifikasi = value;
    // Lakukan sesuatu dengan nilai selectedNoIdentifikasi, seperti menyimpannya ke dalam variabel atau state

    // Contoh: Menyimpannya dalam variabel
    console.log(selectedNoIdentifikasi);
    setNoIdentifikasi(selectedNoIdentifikasi);
    getDataHeaderMesin(selectedNoIdentifikasi, nppbkc);
  };

  const handleGetNppbkc = (value, e) => {
    const selectedNppbkc = value;
    const selectedData = dataNppbkc.find((nbkc) => nbkc.nppbkc === selectedNppbkc);
    if (selectedData) {
      setNppbkc(selectedNppbkc);
      setNama_Perusahaan(selectedData.namaPerusahaan);
      setAlamat(selectedData.alamatPemilik);
      getDataHeader(selectedNppbkc);
    }
  };

  const handleGetNppbkcBaru = (value, e) => {
    const selectedNppbkcBaru = value;
    const selectedDataBaru = dataNppbkc.find((nbkcbaru) => nbkcbaru.nppbkc === selectedNppbkcBaru);
    if (selectedDataBaru) {
      setNppbkcBaru(selectedNppbkcBaru);
      setNama_PerusahaanBaru(selectedDataBaru.namaPerusahaan);
      setAlamatBaru(selectedDataBaru.alamatPemilik);
    }
  };

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "mesinDetilPojo",
      JSON.stringify({
        alamat: alamatBaru,
        idJenisProduksi: values.idJenisProduksi,
        idMerk: values.merkMesin,
        idTipe: values.idTipe,
        jenisMesin: values.jenisMesin,
        kapasitas: values.kapasitasMesin,
        kepemilikan: values.statusKepemilikan,
        keterangan: values.keterangan,
        kondisi: values.kondisiMesin,
        longlat: location,
        namaMerk: values.merkMesin,
        namaPerusahaanLama: nama_perusahaan,
        namaPerusahaanBaru: nama_perusahaanBaru,
        negaraPembuat: values.negara,
        noIdentifikasi: noIdentifikasi,
        nppbkcLama: nppbkc,
        nppbkcBaru: nppbkcBaru,
        serialNumber: values.serialNumber,
        tahunPembuatan: values.tahunPembuatan,
        userRekam: username,
        userUpdate: username,
        // flagAktif: "Y",
      })
    );
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("images", selectedFiles[i]);
    }
    // formData.append("image", ImageUpload);

    const options = {
      method: "POST",
      url: "https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/simpan-mutasi-mesin",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    getDataNppbkc();
    getDataNppbkcBaru();
    getDataJenisProduksi();
    getDataTipeMesin();
    getDataMerk();
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleCameraCapture = () => {
    inputRef.current.click();
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  return (
    <Fragment>
      <div>
        <Card>
          <Form
            name="basic"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 24 }}
            // style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Card title="Data Perusahaan Asal">
              <Form.Item label="NPPBKC" name="nppbkc">
                <Select onChange={handleGetNppbkc}>
                  {dataNppbkc.map((nbkc) => {
                    return (
                      <Option key={nbkc.nppbkc} value={nbkc.nppbkc}>
                        {nbkc.nppbkc}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Nama Perusahaan" name="namaPerusahaan">
                <input type="hidden" value={nama_perusahaan} onChange={(e) => setNama_Perusahaan(e.target.value)} />
                <Input disabled type="text" value={nama_perusahaan} onChange={(e) => setNama_Perusahaan(e.target.value)} />
              </Form.Item>

              <Form.Item label="Alamat" name="alamat">
                <input type="hidden" value={alamat} onChange={(e) => setAlamat(e.target.value)} />
                <Input disabled value={alamat} onChange={(e) => setAlamat(e.target.value)} />
              </Form.Item>
            </Card>
            <Card title="Data Perusahaan Tujuan">
              <Form.Item label="NPPBKC" name="nppbkcBaru">
                <Select onChange={handleGetNppbkcBaru}>
                  {dataNppbkcBaru.map((nbkcbaru) => {
                    return (
                      <Option key={nbkcbaru.nppbkc} value={nbkcbaru.nppbkc}>
                        {nbkcbaru.nppbkc}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Nama Perusahaan" name="namaPerusahaan">
                <input type="hidden" value={nama_perusahaanBaru} onChange={(e) => setNama_PerusahaanBaru(e.target.value)} />
                <Input disabled type="text" value={nama_perusahaanBaru} onChange={(e) => setNama_PerusahaanBaru(e.target.value)} />
              </Form.Item>

              <Form.Item label="Alamat" name="alamat">
                <input type="hidden" value={alamatBaru} onChange={(e) => setAlamatBaru(e.target.value)} />
                <Input disabled value={alamatBaru} onChange={(e) => setAlamatBaru(e.target.value)} />
              </Form.Item>
            </Card>
            <Card title="Data Mesin">
              <Form.Item
                label="No Identifikasi"
                name="noIdentifikasi"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select onChange={handleGetDataMesin}>
                  {dataHeader.map((mesin) => {
                    return (
                      <Option key={mesin.idMesin} value={mesin.noIdentifikasi}>
                        {mesin.noIdentifikasi}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Merk Mesin"
                name="merkMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select defaultValue={dataHeaderMesin.id_merk}>
                  {merkMesin.map((merk) => {
                    return (
                      <Option key={merk.idMerk} value={merk.idMerk}>
                        {merk.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Jenis Mesin"
                name="jenisMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select value={dataHeader.jenis_mesin}>
                  <Option value="Pelinting">Pelinting</Option>
                  <Option value="Pengemas">Pengemas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Serial Number"
                name="serialNumber"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input value={dataHeaderMesin[0].serial_number} />
              </Form.Item>

              <Form.Item
                label="Tipe Mesin"
                name="idTipe"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {tipeMesin.map((tipe) => {
                    return (
                      <Option key={tipe.idTipeMesin} value={tipe.idTipeMesin}>
                        {tipe.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Jenis Produksi"
                name="idJenisProduksi"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  {jenisMesin.map((jenis) => {
                    return (
                      <Option key={jenis.idJenisProduksi} value={jenis.idJenisProduksi}>
                        {jenis.nama}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item label="Tahun Pembuatan" name="tahunPembuatan">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Negara"
                name="negara"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Kapasitas Mesin" name="kapasitasMesin">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Kondisi Mesin"
                name="kondisiMesin"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Baru"}>Baru</Option>
                  <Option value={"Bekas"}>Bekas</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Status Kepemilikan"
                name="statusKepemilikan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select>
                  <Option value={"Milik Sendiri"}>Milik Sendiri</Option>
                  <Option value={"Sewa"}>Sewa</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Keterangan"
                name="keterangan"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item>
                <div className="form-group basic">
                  <input type="file" accept="image/*" multiple onChange={handleFileChange} style={{ display: "none" }} ref={inputRef} />
                  <div className="selected-images">
                    {selectedFiles.map((file, index) => (
                      <div key={index} className="image-container">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index + 1}`}
                          className="image-preview"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                        <br />
                        <Button
                          size="small"
                          type="primary"
                          danger="true"
                          block
                          onClick={() => handleRemoveImage(index)}
                          style={{
                            marginLeft: "100px",
                            objectFit: "cover",
                            display: "block",
                            margin: "0 auto",
                          }}>
                          -Delete Photo-
                        </Button>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Item>
              <Card>
                <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
                  <Button size="medium" type="primary" block onClick={handleCameraCapture}>
                    AMBIL FOTO
                  </Button>
                </Form.Item>
              </Card>
            </Card>
            <Card>
              <Form.Item wrapperCol={{ offset: 0, span: 3, margintop: 80 }}>
                <Button size="large" type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Card>
      </div>
    </Fragment>
  );
}
export default RekamMutasi;
