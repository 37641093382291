import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table, Select, Button, DatePicker, Checkbox, message } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import swal from "sweetalert";
import FormItem from "antd/es/form/FormItem";

const { Option } = Select;

function FormF(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const nppbkc = props.nppbkc;

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/registrasi/detil/reg-F?nppbkc=" + nppbkc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };

    getDataHeader();
  }, [nppbkc]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "detilBanks",
      JSON.stringify({
        atasNama: values.atasNama,
        idRegF: "",
        idRegFBank: "",
        jenisRekening: values.jenisRekening,
        jenisValuta: values.jenisValuta,
        namaBank: values.namaBank,
        nomorRekening: values.nomorRekening,
      })
    );
    formData.append(
      "detilPelanggans",
      JSON.stringify({
        idRegF: "",
        idRegFPelanggan: "",
        kabupatenKota: values.kabupatenKota,
        jenisValuta: values.jenisValuta,
        nama: values.nama,
        provinsi: values.provinsi,
      })
    );
    formData.append(
      "detilPemasoks",
      JSON.stringify({
        idRegF: "",
        idRegFPemasok: "",
        kabupatenKota: values.kabupatenKota,
        nama: values.nama,
        provinsi: values.provinsi,
      })
    );

    formData.append("aksesLokasi", values.aksesLokasi);
    formData.append("auditAkuntanPublik", values.auditAkuntanPublik);
    formData.append("auditDjbc", values.auditDjbc);
    formData.append("auditDjp", values.auditDjp);
    formData.append("idRegF", values.idRegE);
    formData.append("idRegistrasi", values.idRegistrasi);
    formData.append("jumlahPelanggan", values.jumlahPelanggan);
    formData.append("strukturOrganisasi", values.strukturOrganisasi);

    const options = {
      method: "POST",
      url: "https://one-delta-api-g2ascpywyq-et.a.run.app/registrasi/simpan-reg-F",
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    console.log(formData);
    axios(options)
      .then(function (response) {
        console.log(response);
        swal({
          title: "Selamat!",
          text: "Berhasil Rekam Mesin!",
          icon: "success",
        }).then((value) => {});
      })
      .catch(function (error) {
        message.error("Gagal simpan data");
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div>
        <Card title="Data Lainnya">
          <div className="kt-portlet__body">
            <div className="kt-portlet__head-toolbar"></div>
            <div className="form-group form-group-xs row">
              <label className="col-3 col-form-label">Stuktur Organisasi Perusahaan</label>
              <div className="col-3">
                <FormItem name="strukturOrganisasi">
                  <Select style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-3 col-form-label">Apakah Sudah Punya NPPBKC</label>
              <div className="col-3">
                <Input style={{ width: "100%" }} type="number" />
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <h6 class="kt-portlet__head-title">Rekening Bank</h6>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nama Bank</label>
              <div className="col-3">
                <FormItem name="namaBank">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
              <label className="col-2 col-form-label"></label>
              <label className="col-2 col-form-label">Jenis Rekening</label>
              <div className="col-3">
                <FormItem name="jenisRekening">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Nomor Rekening</label>
              <div className="col-3">
                <FormItem name="nomorRekening">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
              <label className="col-2 col-form-label"></label>
              <label className="col-2 col-form-label">Jenis Valuta</label>
              <div className="col-3">
                <FormItem name="jenisValuta">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <label className="col-2 col-form-label">Atas Nama</label>
              <div className="col-3">
                <FormItem name="atasNama">
                  <Input style={{ width: "100%" }} />
                </FormItem>
              </div>
            </div>
            <div className="form-group form-group-xs row">
              <div className="col-3">
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </div>
              <div className="col-3">
                <Button type="primary">Reset</Button>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>
        <Card>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Audit oleh DJBC</label>
            <div className="col-3">
              <FormItem name="auditDjbc">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Audit Oleh DJP</label>
            <div className="col-3">
              <FormItem name="auditDjp">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Audit Akuntan Public</label>
            <div className="col-3">
              <FormItem name="auditAkuntanPublik">
                <Select style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <h6 class="kt-portlet__head-title">Pemasok Langsung Utama</h6>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Nama</label>
            <div className="col-3">
              <FormItem name="nama">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Kabupaten Kota</label>
            <div className="col-3">
              <FormItem name="kabupatenKota">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Provinsi</label>
            <div className="col-3">
              <FormItem name="provinsi">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </div>
            <div className="col-3">
              <Button type="primary">Reset</Button>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
        </Card>
        <Card>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Jumlah Pelanggan Langsung</label>
            <div className="col-3">
              <Input style={{ width: "100%" }} />
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <h6 class="kt-portlet__head-title">Pelanggan Langsung</h6>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Nama</label>
            <div className="col-3">
              <FormItem name="nama">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
            <label className="col-1 col-form-label"></label>
            <label className="col-2 col-form-label">Kabupaten Kota</label>
            <div className="col-3">
              <FormItem name="kabupatenKota">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Provinsi</label>
            <div className="col-3">
              <FormItem name="provinsi">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
          <div className="form-group form-group-xs row">
            <div className="col-3">
              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </div>
            <div className="col-3">
              <Button type="primary">Reset</Button>
            </div>
          </div>
        </Card>
        <Card>
          <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
            <Table />
          </div>
          <label className="col-2 col-form-label"></label>
          <div className="form-group form-group-xs row">
            <label className="col-2 col-form-label">Akses Kelokasi Pabrik (Khusus Pengusaha Pabrik)</label>
            <div className="col-3">
              <FormItem name="aksesLokasi">
                <Input style={{ width: "100%" }} />
              </FormItem>
            </div>
          </div>
        </Card>

        <div className="form-group form-group-xs row">
          <label className="col-2 col-form-label"></label>
          <label className="col-2 col-form-label"></label>
          <label className="col-1 col-form-label"></label>
          <div className="col-3">
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default FormF;
