import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilHtBACK7(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck2/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_detil = [
    {
      title: "MERK",
      width: 270,
      key: "nama_merk",
      dataIndex: "nama_merk",
      fixed: "left",
    },
    {
      title: "JENIS PRODUKSI",
      width: 150,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
    },
    {
      title: "ISI",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "HJE",
      key: "hje",
      dataIndex: "hje",
      width: 180,
      render: (hje) => (
        <span>
          {hje
            ? hje.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif
            ? tarif.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "SERI PITA",
      key: "seri_pita_golongan",
      dataIndex: "seri_pita_golongan",
      width: 150,
    },
    {
      title: "TAHUN",
      key: "tahun_pita",
      dataIndex: "tahun_pita",
    },
    {
      title: "KEMASAN PBCK-7",
      key: "jumlah_kemasan_pbck7",
      dataIndex: "jumlah_kemasan_pbck7",
      render: (jumlah_kemasan_pbck7) => (jumlah_kemasan_pbck7 ? jumlah_kemasan_pbck7.toLocaleString("id-ID") : "-"),
    },
    {
      title: "KEMASAN BACK-1",
      key: "jumlah_kemasan_back1",
      dataIndex: "jumlah_kemasan_back1",
      render: (jumlah_kemasan_back1) => (jumlah_kemasan_back1 ? jumlah_kemasan_back1.toLocaleString("id-ID") : "-"),
    },
    {
      title: "KEMASAN BACK-3",
      key: "jumlah_kemasan_back3",
      dataIndex: "jumlah_kemasan_back3",
      render: (jumlah_kemasan_back3) => (jumlah_kemasan_back3 ? jumlah_kemasan_back3.toLocaleString("id-ID") : "-"),
    },
    {
      title: "CUKAI BACK-3",
      key: "jumlah_cukai_back3",
      dataIndex: "jumlah_cukai_back3",
      render: (jumlah_cukai_back3) => (
        <span>
          {jumlah_cukai_back3
            ? jumlah_cukai_back3.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },

    {
      title: "KEPING CK2",
      key: "jumlah_keping_ck2",
      dataIndex: "jumlah_keping_ck2",
      render: (jumlah_keping_ck2) => (jumlah_keping_ck2 ? jumlah_keping_ck2.toLocaleString("id-ID") : "-"),
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Perusahaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal PBCK-7"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_pbck7 && dataHeader[0].tanggal_pbck7
                      ? `${dataHeader[0].nomor_pbck7} / ${moment(dataHeader[0].tanggal_pbck7).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Pemeriksaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={dataHeader[0].tanggal_pemeriksaan ? `${moment(dataHeader[0].tanggal_pemeriksaan).format("DD-MM-YYYY")}` : "-"}
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal BACK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_back1 && dataHeader[0].tanggal_back1
                      ? `${dataHeader[0].nomor_back1} / ${moment(dataHeader[0].tanggal_back1).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal SP"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_sp && dataHeader[0].tanggal_sp
                      ? `${dataHeader[0].nomor_sp} / ${moment(dataHeader[0].tanggal_sp).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal Segel"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_segel && dataHeader[0].tanggal_segel
                      ? `${dataHeader[0].nomor_segel} / ${moment(dataHeader[0].tanggal_segel).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/Tanggal Buka Segel"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_buka_segel && dataHeader[0].tanggal_buka_segel
                      ? `${dataHeader[0].nomor_buka_segel} / ${moment(dataHeader[0].tanggal_buka_segel).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal Persetujuan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_persetujuan && dataHeader[0].tanggal_persetujuan
                      ? `${dataHeader[0].nomor_persetujuan} / ${moment(dataHeader[0].tanggal_persetujuan).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal KEP. Pengawasan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_kep_pengawas && dataHeader[0].tanggal_kep_pengawas
                      ? `${dataHeader[0].nomor_kep_pengawas} / ${moment(dataHeader[0].tanggal_kep_pengawas).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal PBCK-3"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_pbck3 && dataHeader[0].tanggal_kep_pengawas
                      ? `${dataHeader[0].nomor_pbck3} / ${moment(dataHeader[0].tanggal_pbck3).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal BACK-3"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_back3 && dataHeader[0].tanggal_kep_pengawas
                      ? `${dataHeader[0].nomor_back3} / ${moment(dataHeader[0].tanggal_back3).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor / Tanggal ST"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_st && dataHeader[0].tanggal_st
                      ? `${dataHeader[0].nomor_st} / ${moment(dataHeader[0].tanggal_st).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor/ Tanggal CK-2"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={
                    dataHeader[0].nomor_ck2 && dataHeader[0].tanggal_ck2
                      ? `${dataHeader[0].nomor_ck2} / ${moment(dataHeader[0].tanggal_ck2).format("DD-MM-YYYY")}`
                      : "- / -"
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Pemusnahan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_pemusnahan ? `${moment(dataHeader[0].tanggal_pemusnahan).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Lokasi Pemusnahan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].lokasi_pemusnahan ? `${dataHeader[0].lokasi_pemusnahan}` : " -"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"Cara Pemusnahan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <TextArea disabled value={dataHeader[0].cara_pemusnahan ? `${dataHeader[0].cara_pemusnahan}` : " -"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Lunas BP"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_lunas_bp ? `${moment(dataHeader[0].tanggal_lunas_bp).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
            <div className=" col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Status"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].status ? `${dataHeader[0].status}` : "-"} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="DETIL DATA">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil_ck2}
              columns={tbl_detil}
              scroll={{
                x: 2200,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilHtBACK7;
