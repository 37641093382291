import React, { Fragment, useState, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, Input, Space } from "antd";
import DetilNppbkc from "./detil";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { Icon } from "leaflet";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Nppbkc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetilNppbkc: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/nppbkc/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetilNppbkc: false,
    });
  };

  detilNppbkc = (nppbkc) => {
    this.setState({
      nppbkc: nppbkc,
      showDetilNppbkc: true,
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  handleExportToExcel = () => {
    const { hasil } = this.state;

    // Buat array objek untuk data yang ingin diekspor ke Excel
    const dataToExport = hasil.map((item) => {
      return {
        NPPBKC: item.nppbkc,
        PERUSAHAAN: item.namaPerusahaan,
        PEMILIK: item.pemilik,
        "ALAMAT PEMILIK": item.alamatPemilik,
        "TANGGAL NPPBKC": item.tanggalNppbkc,
        "JENIS USAHA": item.jenisUsaha,
        "JENIS BKC": item.jenisBkc,
        "TANGGAL BERAKHIR": item.tanggalBerakhir,
        "+- HARI": item.kurlebHari,
        STATUS: item.status,
      };
    });

    // Buat workbook baru menggunakan library xlsx
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Tambahkan worksheet ke workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Simpan workbook menjadi file Excel
    XLSX.writeFile(workbook, "data.xlsx");
  };

  render() {
    const nppbkc = [
      {
        title: "NPPBKC",
        width: 100,
        key: "nppbkc",
        dataIndex: "nppbkc",
        fixed: "left",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
      },
      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        dataIndex: "namaPerusahaan",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("namaPerusahaan"),
      },
      {
        title: "PEMILIK",
        key: "pemilik",
        dataIndex: "pemilik",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("pemilik"),
      },
      {
        title: "ALAMAT PEMILIK",
        key: "alamatPemilik",
        dataIndex: "alamatPemilik",
        width: 800,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("alamatPemilik"),
      },
      {
        title: "TANGGAL NPPBKC",
        key: "tanggalNppbkc",
        dataIndex: "tanggalNppbkc",
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "JENIS USAHA",
        key: "jenisUsaha",
        dataIndex: "jenisUsaha",
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("jenisUsaha"),
      },
      {
        title: "JENIS BKC",
        key: "jenisBkc",
        dataIndex: "jenisBkc",
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("jenisBkc"),
      },
      {
        title: "TANGGAL BERAKHIR",
        key: "tanggalBerakhir",
        dataIndex: "tanggalBerakhir",
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "+- HARI",
        key: "kurlebHari",
        dataIndex: "kurlebHari",
      },
      {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
        // sortDirections: ["descend", "ascend"],
        // ...this.getColumnSearchProps("status"),
      },
      {
        title: "#AKSI",
        key: "nppbkc",
        dataIndex: "nppbkc",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.nppbkc.split(",").map((nppbkc) => {
              let button = (
                <Button type="primary" size="medium" onClick={() => this.detilNppbkc(row.nppbkc)}>
                  <i className="fa fa-arrow-right" />
                </Button>
              );
              return <span> {button} </span>;
            })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA NPPBKC">
                <Button type="primary" onClick={this.handleExportToExcel} style={{ position: "absolute", top: 20, right: 22 }}>
                  Export to Excel
                </Button>
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={nppbkc}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 3200,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA NPPBKC"} placement="right" width={drawerWidth} open={this.state.showDetilNppbkc} onClose={this.handleOnClose}>
            <DetilNppbkc nppbkc={this.state.nppbkc} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Nppbkc;
