import React, { Fragment, Component } from "react";
import { Row, Col, Card, Table, Form, Button, Drawer, Input, Space } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import Detil from "./detil";
const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;
const rekamWidth = screenWidth * 1;

class Tracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetil: false,
      showEdit: false,
      showRekam: false,
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
    };
  }

  fetch = async () => {
    this.setState({ loading: true });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/browse/mesin/pegawai", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: this.state.token,
        },
      })
      .then((data) => {
        const pagination = { ...this.state.pagination };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetil: false,
      showRekam: false,
      showEdit: false,
    });
  };

  detilData = (idMesin) => {
    this.setState({
      idMesin: idMesin,
      showDetil: true,
    });
    console.log(idMesin);
  };

  handleTableChange = (pagination, filters) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const Tb_Mesin = [
      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        width: 150,
        fixed: "left",
        dataIndex: "namaPerusahaan",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("namaPerusahaan"),
      },

      {
        title: "MERK",
        key: "idMerk",
        dataIndex: "idMerk",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("idMerk"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "JENIS",
        key: "jenisMesin",
        dataIndex: "jenisMesin",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("jenisMesin"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "NO IDENTIFIKASI",
        key: "noIdentifikasi",
        dataIndex: "noIdentifikasi",
        width: 210,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("noIdentifikasi"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "SERIAL NUMBER",
        key: "serialNumber",
        dataIndex: "serialNumber",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("serialNumber"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "KEPEMILIKAN",
        key: "kepemilikan",
        dataIndex: "kepemilikan",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("kepemilikan"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "WAKTU REKAM TERAKHIR",
        key: "updatedAt",
        dataIndex: "updatedAt",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("updatedAt"),
        render: (text) => {
          return text ? text : "-";
        },
      },

      {
        title: "#AKSI",
        key: "idMesin",
        dataIndex: "idMesin",
        width: 50,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.idMesin
              .toString()
              .split(",")
              .map((idMesin) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilData(idMesin)}>
                    <i className="fa fa-search" />
                  </Button>
                );
                return <span>{button}</span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA TRACKING">
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={Tb_Mesin}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 1800,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL TRACKING MESIN"} placement="right" width={drawerWidth} open={this.state.showDetil} onClose={this.handleOnClose}>
            <Detil idMesin={this.state.idMesin} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Tracking;
