import React, { useState } from "react";
import { Form, DatePicker, Button } from "antd";
import axios from "axios";

const { RangePicker } = DatePicker;

const DownloadForm = () => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      const [startDate, endDate] = values.dateRange;

      // Lakukan permintaan download menggunakan Axios
      const url = "https://one-delta-api-g2ascpywyq-et.a.run.app/mesin/download-mesin";
      const downloadUrl = `${url}?tglAwal=${startDate.format("YYYY-MM-DD")}&tglAkhir=${endDate.format("YYYY-MM-DD")}`;

      axios
        .get(downloadUrl, { responseType: "blob" })
        .then((response) => {
          // Mengolah respons jika berhasil
          const blob = new Blob([response.data]);
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = "data.xlsx";
          downloadLink.click();
        })
        .catch((error) => {
          // Mengolah respons jika terjadi kesalahan
          console.error("Error:", error);
        });
    });
  };

  return (
    <Form form={form} onFinish={handleFormSubmit} layout="vertical">
      <Form.Item name="dateRange" label="Rentang Tanggal" rules={[{ required: true, message: "Pilih rentang tanggal" }]}>
        <RangePicker />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Download
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DownloadForm;
