import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilCK6(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck6/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_ck = [
    {
      title: "MERK",
      width: 230,
      key: "nama_merk",
      dataIndex: "nama_merk",
      fixed: "left",
    },
    {
      title: "JENIS",
      width: 100,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
    },
    {
      title: "VOLUME",
      key: "volume",
      dataIndex: "volume",
      width: 100,
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif
            ? tarif.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "KADAR",
      key: "kadar",
      dataIndex: "kadar",
      width: 100,
    },
    {
      title: "GOLONGAN",
      key: "golongan",
      dataIndex: "golongan",
      width: 150,
    },
    {
      title: "NO KOLLI",
      key: "nomor_kolli",
      dataIndex: "nomor_kolli",
      width: 100,
    },
    {
      title: "JENIS KOLLI",
      key: "jenis_kolli",
      dataIndex: "jenis_kolli",
      width: 100,
    },
    {
      title: "HJE",
      key: "hje",
      dataIndex: "hje",
      width: 180,
      render: (hje) => (
        <span>
          {hje
            ? hje.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "JUMLAH BARANG",
      key: "jumlah_barang",
      dataIndex: "jumlah_barang",
      width: 150,
    },
    {
      title: "KETERANGAN",
      key: "keterangan",
      dataIndex: "keterangan",
      width: 180,
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Perusahaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan ? `${dataHeader[0].nama_perusahaan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          {/* <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
                  <div className="col-lg-12 col-md-18 col-sm-24">
                    <Form.Item label={"Alamat Perusahaan"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                      <TextArea disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
                    </Form.Item>
                  </div>
                </div> */}
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor CK-6"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_ck6 ? `${dataHeader[0].nomor_ck6}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal CK-6"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_ck6 ? `${moment(dataHeader[0].tanggal_ck6).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor Invoice"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_invoice ? `${dataHeader[0].nomor_invoice}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal Invoice"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_invoice ? `${moment(dataHeader[0].tanggal_invoice).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Alat Angkut"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].alat_angkut ? `${dataHeader[0].alat_angkut}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor Polisi / Voy / Flight"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].voy_flight ? `${dataHeader[0].voy_flight}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Jangka Waktu"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jangka_waktu ? `${dataHeader[0].jangka_waktu} Hari` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Pemberitahu"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_pemberitahu ? `${dataHeader[0].nama_pemberitahu}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tempat"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tempat_diberitahukan ? `${dataHeader[0].tempat_diberitahukan}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={dataHeader[0].tanggal_diberitahukan ? `${moment(dataHeader[0].tanggal_diberitahukan).format("DD-MM-YYYY")}` : "-"}
                />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_ck}
              scroll={{
                x: 1800,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilCK6;
