import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilCk4MMEA(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck4/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_detil = [
    {
      title: "MERK",
      width: 370,
      key: "nama_merek",
      dataIndex: "nama_merek",
      fixed: "left",
    },
    {
      title: "JENIS",
      width: 100,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
    },
    {
      title: "VOLUME",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif
            ? tarif.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 2,
              })
            : "Rp. -"}
        </span>
      ),
    },
    {
      title: "NO PRODUKSI",
      key: "nomor_produksi_barang",
      dataIndex: "nomor_produksi_barang",
      width: 150,
    },
    {
      title: "TANGGAL PRODUKSI",
      key: "tanggal_produksi_barang",
      dataIndex: "tanggal_produksi_barang",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "JUMLAH PRODUKSI",
      key: "jumlah_produksi_satuan",
      dataIndex: "jumlah_produksi_satuan",
      render: (jumlah_produksi_satuan) => (jumlah_produksi_satuan ? jumlah_produksi_satuan.toLocaleString("id-ID") : "-"),
    },
    {
      title: "JUMLAH KEMASAN",
      key: "jumlah_kemasan",
      dataIndex: "jumlah_kemasan",
      render: (jumlah_kemasan) => (jumlah_kemasan ? jumlah_kemasan.toLocaleString("id-ID") : "-"),
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nama Perusahaan"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan ? `${dataHeader[0].nama_perusahaan}` : "-"} />
              </Form.Item>
            </div>
          </div>
          {/* <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
                  <div className="col-lg-12 col-md-18 col-sm-24">
                    <Form.Item label={"Alamat Perusahaan"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                      <TextArea disabled value={dataHeader[0].nppbkc ? `${dataHeader[0].nppbkc}` : "-"} />
                    </Form.Item>
                  </div>
                </div> */}
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Nomor CK-4C"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_ck4 ? `${dataHeader[0].nomor_ck4}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Tanggal CK-4C"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_ck4 ? `${moment(dataHeader[0].tanggal_ck4).format("DD-MM-YYYY")}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Jenis BKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jenis_bkc ? `${dataHeader[0].jenis_bkc}` : "-"} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Status"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].status ? `${dataHeader[0].status}` : "-"} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Waktu Produksi Awal"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={dataHeader[0].tanggal_produksi_awal ? `${moment(dataHeader[0].tanggal_produksi_awal).format("DD-MM-YYYY")}` : "-"}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"Waktu Produksi Akhir"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input
                  disabled
                  value={dataHeader[0].tanggal_produksi_akhir ? `${moment(dataHeader[0].tanggal_produksi_akhir).format("DD-MM-YYYY")}` : "-"}
                />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_detil}
              scroll={{
                x: 1800,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilCk4MMEA;
