import React from "react";
import Index from "../views/dashboard/index";
import { Switch, Route } from "react-router-dom";
// user
// import UserProfile from "../views/dashboard/app/user-profile";
import UserAdd from "../views/dashboard/app/user-add";
import UserList from "../views/dashboard/app/user-list";
import userProfileEdit from "../views/dashboard/app/user-privacy-setting";
// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetcard from "../views/dashboard/widget/widgetcard";
import Widgetchart from "../views/dashboard/widget/widgetchart";
// icon
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";
// Form
import FormElement from "../views/dashboard/from/form-element";
import FormValidation from "../views/dashboard/from/form-validation";
import FormWizard from "../views/dashboard/from/form-wizard";
// table
import BootstrapTable from "../views/dashboard/table/bootstrap-table";
import TableData from "../views/dashboard/table/table-data";

// map
import Vector from "../views/dashboard/maps/vector";
import Google from "../views/dashboard/maps/google";

//extra
import PrivacyPolicy from "../views/dashboard/extra/privacy-policy";
import TermsofService from "../views/dashboard/extra/terms-of-service";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";
//Special Pages
import Billing from "../views/dashboard/special-pages/billing";
import Kanban from "../views/dashboard/special-pages/kanban";
import Pricing from "../views/dashboard/special-pages/pricing";
import Timeline from "../views/dashboard/special-pages/timeline";
import Calender from "../views/dashboard/special-pages/calender";
//admin
import Admin from "../views/dashboard/admin/admin";

const DefaultRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={300}>
        <Switch>
          <Route path="/dashboard" exact component={Index} />
          {/* user */}
          {/* <Route path="/dashboard/cukai/nppbkc/"     exact component={Nppbkc} /> */}
          {/* <Route path="/dashboard/app/user-add" exact component={UserAdd} />
          <Route path="/dashboard/app/user-list" exact component={UserList} />
          <Route path="/dashboard/app/user-privacy-setting" exact component={userProfileEdit} /> */}

          {/* map */}
          {/* <Route path="/dashboard/map/vector" exact component={Vector} />
          <Route path="/dashboard/map/google" exact component={Google} /> */}
          {/* extra */}
          {/* <Route path="/dashboard/extra/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/dashboard/extra/terms-of-service" exact component={TermsofService} /> */}
          {/*admin*/}
          <Route path="/dashboard/admin/admin" exact component={Admin} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
