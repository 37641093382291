import React, { Fragment, useState, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, Input, Space } from "antd";
import DetilCK6 from "./detil";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Ck6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetilCK6: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck6/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetilCK6: false,
    });
  };

  detilCK = (idCK) => {
    this.setState({
      idCK: idCK,
      showDetilCK6: true,
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const tabelck = [
      {
        title: "DOKUMEN",
        children: [
          {
            title: "NO CK-6",
            width: 100,
            key: "nppbkc",
            dataIndex: "nppbkc",
            fixed: "left",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nppbkc"),
          },
          {
            title: "TANGGAL CK-6",
            key: "tanggalCk5",
            dataIndex: "tanggalCk6",
            width: 100,
            fixed: "left",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("tanggalCk6"),
            render: (text) => {
              return moment(text).format("DD-MM-YYYY");
            },
          },
        ],
      },

      {
        title: "PEMASOK",
        children: [
          {
            title: "KODE KANTOR",
            width: 100,
            key: "kodeKantor",
            dataIndex: "kodeKantor",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("kodeKantor"),
          },
          {
            title: "JENIS",
            width: 100,
            key: "jenisBkc",
            dataIndex: "jenisBkc",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("jenisBkc"),
          },
          {
            title: "NPPBKC",
            width: 100,
            key: "nppbkc",
            dataIndex: "nppbkc",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nppbkc"),
          },
          {
            title: "NAMA",
            width: 200,
            key: "namaPerusahaan",
            dataIndex: "namaPerusahaan",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("namaPerusahaan"),
          },
        ],
      },

      {
        title: "PENGGUNA",
        children: [
          {
            title: "KODE KANTOR",
            width: 100,
            key: "kodeKantor",
            dataIndex: "kodeKantor",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("kodeKantor"),
          },
          {
            title: "JENIS",
            width: 100,
            key: "jenisBkc",
            dataIndex: "jenisBkc",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("jenisBkc"),
          },
          {
            title: "IDENTITAS",
            width: 100,
            key: "nppbkc",
            dataIndex: "nppbkc",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("nppbkc"),
          },
          {
            title: "NAMA",
            width: 100,
            key: "namaPemberitahu",
            dataIndex: "namaPemberitahu",
            sortDirections: ["descend", "ascend"],
            ...this.getColumnSearchProps("namaPemberitahu"),
          },
        ],
      },

      {
        title: "#AKSI",
        key: "idCk6",
        dataIndex: "idCk6",
        width: 100,
        fixed: "right",
        render: (text, row) => (
          <span>
            {row.idCk6
              .toString()
              .split(",")
              .map((idCk6) => {
                let button = (
                  <Button type="primary" size="medium" onClick={() => this.detilCK(row.idCk6)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                );
                return <span> {button} </span>;
              })}
          </span>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="BROWSE DATA CK-6">
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={tabelck}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      onChange={this.handleTableChange}
                      scroll={{
                        x: 1500,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
          <Drawer title={"DETIL DATA CK-6"} placement="right" width={drawerWidth} open={this.state.showDetilCK6} onClose={this.handleOnClose}>
            <DetilCK6 idCK={this.state.idCK} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}

export default Ck6;
