import React, { Fragment, useState, Component } from "react";
import moment from "moment";
import { Row, Col, Card, Table, Upload, Form, Button, Drawer, Input, Space } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { Icon } from "leaflet";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const screenWidth = window.innerWidth;
const drawerWidth = screenWidth * 0.8;

class Rasio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      hasil: [],
      pagination: {},
      loading: false,
      loadingSpin: true,
      showDetilCk: false,
      showDetilMmea: false,
      showDetilNonPita: false,
    };
  }

  fetch = async () => {
    this.setState({
      loading: true,
    });
    axios.defaults.headers.common["Authorization"] = `${this.state.token}`;
    axios
      .get("https://one-delta-api-g2ascpywyq-et.a.run.app/rasio/browse", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((data) => {
        const pagination = {
          ...this.state.pagination,
        };
        pagination.total = data.data.body.data.length;
        console.log(">>>>", data.data.body.data.data);

        this.setState({
          hasil: data.data.body.data.data,
          pagination,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err.message);

        this.setState({
          loading: false,
          hasil: [],
          pagination: false,
        });
        SweetAlert.fire({
          title: "Oops...",
          text: "Data tidak ditemukan!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  handleOnClose = () => {
    this.setState({
      showDetilCk: false,
      showDetilMmea: false,
      showDetilNonPita: false,
    });
  };

  handleTableChange = (pagination, filters) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      loading: true,
    });
    this.fetch({
      size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{
              width: 90,
            }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  componentDidMount() {
    this.fetch();
  }

  render() {
    const tabelck = [
      {
        title: "NPPBKC",
        width: 100,
        key: "nppbkc",
        dataIndex: "nppbkc",
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nppbkc"),
        fixed: "left",
      },

      {
        title: "PERUSAHAAN",
        key: "namaPerusahaan",
        dataIndex: "namaPerusahaan",
        width: 300,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("namaPerusahaan"),
      },
      {
        title: "CK-1 MESIN",
        key: "ck1Mesin",
        dataIndex: "ck1Mesin",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("nomorCk1"),
      },
      {
        title: "CK-1 NON MESIN",
        key: "ck1Nonmesin",
        dataIndex: "ck1Nonmesin",
        width: 150,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("ck1Nonmesin"),
      },
      {
        title: "RASIO",
        key: "rasio",
        dataIndex: "rasio",
        width: 160,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("rasio"),
      },
      {
        title: "PERIODE",
        key: "periode",
        dataIndex: "periode",
        width: 100,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("periode"),
        fixed: "right",
      },
      {
        title: "TAHUN",
        key: "tahun",
        dataIndex: "tahun",
        width: 100,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("tahun"),
        fixed: "right",
      },
      //   {
      //     title: "#AKSI",
      //     key: "idCk1",
      //     dataIndex: "idCk1",
      //     width: 100,
      //     fixed: "right",
      //     render: (text, row) => (
      //       <span>
      //         {row.idCk1
      //           .toString()
      //           .split(",")
      //           .map((idCk1) => {
      //             let button = (
      //               <Button type="primary" size="medium" onClick={() => this.detilCK(row.idCk1, row.jenisBkc, row.golonganPabrik)}>
      //                 <i className="fa fa-arrow-right" />
      //               </Button>
      //             );
      //             return <span>{button}</span>;
      //           })}
      //       </span>
      //     ),
      //   },
    ];

    return (
      <Fragment>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col>
              <Card title="RASIO">
                <div className="table-responsive">
                  <Form>
                    <Table
                      columns={tabelck}
                      dataSource={this.state.hasil}
                      pagination={this.state.pagination}
                      loading={this.state.loading}
                      scroll={{
                        x: 1200,
                      }}
                    />
                  </Form>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default Rasio;
