import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Tabs, Button, Row, Form, Modal } from "antd";
import FormA from "./FormA";
import FormB from "./FormB";
import FormC from "./FormC";
import FormEmpatHT from "./FormD_PabrikHT";
import FormEmpatPabrikMMEA from "./FormD_PabrikMMEA";
import FormEmpatPabrikEA from "./FormD_PabrikEA";
import PengusahaPenyimpanan from "./FormD_Penyimpanan";
import ImportirMMEA from "./FormD_ImporMmea";
import ImportirNonMMEA from "./FormD_ImporNon";
import Penyalur from "./FormD_Penyalur";
import TPE from "./FormD_TPE";
import FormLima from "./FormE";
import FormEnam from "./FormF";

function FormTab(props) {
  const nppbkc = props.nppbkc;
  
  console.log("nppbkc mana: ",nppbkc);
const connectFunction = connect();
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

  
      return (
        <div>
          <Fragment>
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-content  kt-grid__item kt-grid__item--fluid" id="kt_content">
                <Tabs type="card">
                  <TabPane tab="Form A" key="1"  >
                    <FormA nppbkc={nppbkc}  />
                  </TabPane>
                  <TabPane tab="Form B" key="2">
                    <FormB nppbkc={nppbkc}/>
                  </TabPane>
                  <TabPane tab="Form C" key="3">
                    <FormC nppbkc={nppbkc}/>
                  </TabPane>
                  <TabPane tab="Form D" key="4">
                    <FormEmpatHT />
                  </TabPane>
                  {/* <TabPane tab="Form D " key="5">
                    <FormEmpatPabrikMMEA />
                  </TabPane>
                  <TabPane tab="Form D" key="6">
                    <FormEmpatPabrikEA />
                  </TabPane>
                  <TabPane tab="Form D" key="7">
                    <PengusahaPenyimpanan />
                  </TabPane>
                  <TabPane tab="Form D" key="8">
                    <ImportirMMEA />
                  </TabPane>
                  <TabPane tab="Form D" key="9">
                    <ImportirNonMMEA />
                  </TabPane>
                  <TabPane tab="Form D" key="10">
                    <Penyalur />
                  </TabPane>
                  <TabPane tab="Form D" key="11">
                    <TPE />
                  </TabPane> */}
                  <TabPane tab="Form E" key="12">
                    <FormLima  nppbkc={nppbkc}/>
                  </TabPane>
                  <TabPane tab="Form F" key="13">
                    <FormEnam nppbkc={nppbkc}/>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Fragment>
        </div>
      );
    

};
export default FormTab;
