import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilPpc(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idPppc = props.idPppc;
  console.log("idPppc: ", props.idPppc);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/pppc/detil?id=" + idPppc, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idPppc]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_alamat = [
    {
      title: "JENIS PRODUKSI",
      width: 150,
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
      fixed: "left",
    },
    {
      title: "ISI",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "HJE",
      key: "hje",
      dataIndex: "hje",
      width: 180,
      render: (hje) => (
        <span>
          {hje.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "SERI PITA",
      key: "seri_pita_golongan",
      dataIndex: "seri_pita_golongan",
      width: 150,
    },
    {
      title: "KODE WARNA",
      key: "kode_warna",
      dataIndex: "kode_warna",
    },
    {
      title: "KODE PERSON",
      key: "kode_person",
      dataIndex: "kode_person",
    },
    {
      title: "TAHUN",
      key: "tahun_pita",
      dataIndex: "tahun_pita",
    },
    {
      title: "NO OBC",
      key: "nomor_obc",
      dataIndex: "nomor_obc",
    },
    {
      title: "TANGGAL OBC",
      key: "tanggal_obc",
      dataIndex: "tanggal_obc",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "LEMBAR",
      key: "jumlah_pesan",
      dataIndex: "jumlah_pesan",
      render: (jumlah_pesan) => jumlah_pesan.toLocaleString("id-ID"),
    },
    {
      title: "CUKAI",
      key: "nilai_cukai",
      dataIndex: "nilai_cukai",
      fixed: "right",
      render: (nilai_cukai) => (
        <span>
          {nilai_cukai.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "STATUS PC",
      key: "status",
      dataIndex: "status",
      width: 100,
      fixed: "right",
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PERUSAHAAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"KANTOR PELAYANAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <TextArea value={dataHeader[0].nama_kantor} disabled />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO PPPC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL PPPC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_pppc).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"PERIODE"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].periode_pppc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL PERIODE"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_periode).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO REKOMENDASI"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_rekomendasi_ikk} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL REKOMENDASI"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_rekomendasi_ikk} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL PERMOHONAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_mohon).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"STATUS PPPC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].status} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_alamat}
              scroll={{
                x: 2200,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilPpc;
