import React, { useState, useEffect, Fragment } from "react";
import { Card, Form, Input, Table } from "antd";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

function DetilCK(props) {
  const [dataHeader, setDataHeader] = useState([""]);
  const idCK = props.idCK;
  console.log("idCK: ", props.idCK);

  useEffect(() => {
    const getDataHeader = () => {
      axios
        .get("https://one-delta-api-g2ascpywyq-et.a.run.app/ck1/detil?id=" + idCK, {
          headers: {},
        })
        .then((res) => {
          const result = res.data.body.data.data;
          setDataHeader(result);
          // console.log("HEADER: ", result);
        })
        .catch((error) => {});
    };
    getDataHeader();
  }, [idCK]);

  console.log("HEADER BAWAH: ", dataHeader[0].npwp);

  const tbl_ck = [
    {
      title: "MEREK",
      width: 280,
      key: "nama_merk",
      dataIndex: "nama_merk",
      fixed: "left",
    },
    {
      title: "JENIS PRODUKSI",
      key: "jenis_produksi",
      dataIndex: "jenis_produksi",
      width: 150,
    },
    {
      title: "ISI",
      key: "isi",
      dataIndex: "isi",
      width: 100,
    },
    {
      title: "HJE",
      key: "hje",
      dataIndex: "hje",
      width: 180,
      render: (hje) => (
        <span>
          {hje.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "TARIF",
      key: "tarif",
      dataIndex: "tarif",
      width: 180,
      render: (tarif) => (
        <span>
          {tarif.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "SERI PITA",
      key: "seri_pita_golongan",
      dataIndex: "seri_pita_golongan",
      width: 150,
    },
    {
      title: "KODE WARNA",
      key: "kode_warna",
      dataIndex: "kode_warna",
    },
    {
      title: "KODE PERSON",
      key: "kode_personal",
      dataIndex: "kode_personal",
    },
    // {
    //   title: "TAHUN",
    //   key: "tahun_pita",
    //   dataIndex: "tahun_pita",
    // },
    // {
    //   title: "NO OBC",
    //   key: "nomor_obc",
    //   dataIndex: "nomor_obc",
    // },
    // {
    //   title: "TANGGAL OBC",
    //   key: "tanggal_obc",
    //   dataIndex: "tanggal_obc",
    //   render: (text) => {
    //     return moment(text).format("DD-MM-YYYY");
    //   },
    // },
    {
      title: "LEMBAR CK-1",
      key: "lembar",
      dataIndex: "lembar",
      render: (lembar) => lembar.toLocaleString("id-ID"),
      fixed: "right",
    },
  ];

  return (
    <Fragment>
      <div>
        <Card title="DATA HEADER">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NPPBKC"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nppbkc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PERUSAHAAN"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_perusahaan} />
              </Form.Item>
            </div>
          </div>
          {/* <div className="form-group row" style={{ marginTop: 15, marginBottom: 0 }}>
                  <div className="col-lg-12 col-md-18 col-sm-24">
                    <Form.Item label={"ALAMAT PERUSAHAAN"} colon={false} wrapperCol={{ sm: 24 }} style={{ width: "100%", marginRight: 0 }}>
                      <TextArea value={dataHeader[0].nama_kantor} disabled />
                    </Form.Item>
                  </div>
                </div> */}
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NO CK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nomor_ck1} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL CK-1"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_ck1).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS BAYAR"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].cara_bayar} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"JENIS PRODUKSI"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].jenis_produksi} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"LOKASI AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].ambil_pita} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"GOLONGAN PABRIK"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].golongan_pabrik} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"IDENTITAS KUASA AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].identitas_kuasa_pc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA KUASA AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_kuasa_pc} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-12 col-md-18 col-sm-24">
              <Form.Item
                label={"TANGGAL LUNAS"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={moment(dataHeader[0].tanggal_lunas).format("DD-MM-YYYY")} />
              </Form.Item>
            </div>
          </div>
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"NAMA PENGAMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].nama_kuasa_pc} />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-12">
              <Form.Item
                label={"TANGGAL AMBIL PITA"}
                colon={false}
                wrapperCol={{
                  sm: 24,
                }}
                style={{
                  width: "100%",
                  marginRight: 0,
                }}>
                <Input disabled value={dataHeader[0].tanggal_penyerahan_pita} />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="RINCIAN">
          <div
            className="form-group row"
            style={{
              marginTop: 15,
              marginBottom: 0,
            }}>
            <Table
              dataSource={dataHeader[0].detil}
              columns={tbl_ck}
              scroll={{
                x: 1800,
              }}
            />
          </div>
        </Card>
      </div>
    </Fragment>
  );
}
export default DetilCK;
